import React from 'react';
import { graphql } from 'gatsby';
import RichText from '../rich-text';
import './index.scss';

export default function QuoteModule ({ arrowColor, arrowDirection, text, attribution }) {
  const imgRendered = (() => {
    if (!attribution?.image?.fixed?.src) return null;
    return (
      <img
        className="attribution-image"
        alt={attribution.image.description}
        src={attribution.image.fixed.src}
        srcSet={attribution.image.fixed.srcSet}
      />
    );
  })();

  let attributionTitle = '';
  if (attribution?.title?.title) {
    attributionTitle = `, ${attribution?.title?.title}`;
  }

  let className = 'quote-module';

  className += ` color-${arrowColor?.toLowerCase() ?? 'green'}`;
  className += ` direction-${arrowDirection?.toLowerCase() ?? 'left'}`;

  return (
    <section className={className}>
      <div className={`container quote-module__inner`}>
        {imgRendered}
        <div className="quote-text">
          <RichText node={text} />
          <cite>
            <strong>{attribution?.name?.name}</strong>{attributionTitle}
          </cite>
        </div>
      </div>
    </section>
  );
}

export const query = graphql`
fragment quoteModuleFields on ContentfulQuoteModule {
  arrowColor, arrowDirection

  text { raw }

  attribution {
    name { name }
    title { title }

    image {
      description
      fixed(cropFocus: CENTER, height: 200, width: 200, resizingBehavior: FILL) {
        src, srcSet
      }
    }
  }
}
`;
