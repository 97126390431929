import React, { useContext } from 'react';
import { Link } from 'gatsby';
import iconPlus from '../../images/icon-plus.svg';
import iconVideo from '../../images/icon-video.svg';
import iconDownload from '../../images/icon-download.svg';
import { NavigationContext } from './index';
import './feature-card.scss';

const RESOURCE_TYPE_DEFAULT = 'Page';
const RESOURCE_TYPE_ICONS = {
  'Page': iconPlus,
  'Download': iconDownload,
  'Video': iconVideo
};

export default function FeatureCard ({
  id,
  cardBackgroundImage,
  category,
  shortTitle,
  type,
  __typename
}) {
  const navigationMap = useContext(NavigationContext);

  let className = 'feature-card card-washed';

  if (__typename === 'ContentfulResource') {
    className += ' featured-resource-card';
  } else if (__typename === 'ContentfulSolution') {
    className += ' featured-solution-card';
  } else {
    throw new Error(`Invalid __typename: ${__typename}`);
  }

  return (
    <Link className={className} to={navigationMap.get(id)?.path || '#'} >
      <div
        className="color-wash-background"
        style={{ background: `center / cover no-repeat url(${cardBackgroundImage?.fixed?.src})` }}
      ></div>
      <h6 className="card-sq-category">{category}</h6>
      <h5 className="card-sq-heading">{shortTitle}</h5>
      <img
        className="corner-icon"
        src={RESOURCE_TYPE_ICONS[type ?? RESOURCE_TYPE_DEFAULT]}
        alt=""
        role="presentation"
      />
    </Link>
  );
}
