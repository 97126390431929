import React, { useMemo, useContext, useState } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { NavigationContext } from '../navigation';
import vimeoIcon from '../../images/icon-social-vimeo.svg';
import linkedinIcon from '../../images/icon-social-linkedin.svg';
import youtubeIcon from '../../images/icon-social-youtube.svg';
import twitterIcon from '../../images/icon-social-twitter.svg';
import Chevron from '../chevron';
import './index.scss';

export default function Footer ({
  siteLogo, contentfulHomepage, primarySolutionCategories, resourcesPage, aboutPage, careersPage,
  contactUsPage, newsletterSignupPage
}) {
  const [ activeAccordion, setActiveAccordion ] = useState(null);
  const navigationMap = useContext(NavigationContext);

  const data = useStaticQuery(graphql`
    query FooterQuery {
      contentfulGlobalOptions {
        twitterUrl, linkedInUrl, vimeoUrl, youTubeUrl, phoneNumber, copyright
        footerNewsletterSignupHeadline
        footerNewsletterSignupText { footerNewsletterSignupText }
        footerNewsletterSignupButtonText
        address { address }

        legalFooterLinks {
          id
          title { title }
        }
      }
    }
  `);

  const {
    copyright, address, phoneNumber, vimeoUrl, youTubeUrl, twitterUrl, linkedInUrl,
    footerNewsletterSignupHeadline, footerNewsletterSignupText, legalFooterLinks,
    footerNewsletterSignupButtonText
  } = data?.contentfulGlobalOptions;

  const telPhoneNumber = '+' + phoneNumber.replace(/[^0-9]/g, '');

  const solutionsRendered = useMemo(() => {
    return primarySolutionCategories.map((solutionCategory, i) => {
      let childSolutions = solutionCategory.contentfulchildren?.map((s, j) => {
        const c1 = navigationMap.get(s.id);

        return (
          <li key={j}>
            <Link to={c1?.path}>{s.title?.title}</Link>
          </li>
        );
      });

      const c = navigationMap.get(solutionCategory.id);

      const active = solutionCategory.id === activeAccordion;

      return (
        <li
          key={i}
          className={active ? 'active' : ''}
        >
          <div
            className="solution-category-header"
            onClick={() => {
              if (active) {
                setActiveAccordion(null);
              } else {
                setActiveAccordion(solutionCategory.id);
              }
            }}
          >
            <Link className="solution-category" to={c.path}>{solutionCategory.title?.title}</Link>
            <Chevron dir={!active ? 'bottom' : ''} />
          </div>
          <div className="solution-category-links">
            <ul>{childSolutions}</ul>
          </div>
        </li>
      );
    });
  }, [ navigationMap, primarySolutionCategories, activeAccordion ]);

  const socialLinks = [
    vimeoUrl ? (
      <li key="vimeo">
        <a href={vimeoUrl} target="_blank" rel="noreferrer">
          <img src={vimeoIcon} alt="Vimeo" />
        </a>
      </li>
    ) : null,
    youTubeUrl ? (
      <li key="youtube">
        <a href={youTubeUrl} target="_blank" rel="noreferrer">
          <img src={youtubeIcon} alt="YouTube" />
        </a>
      </li>
    ) : null,
    twitterUrl ? (
      <li key="twitter">
        <a href={twitterUrl} target="_blank" rel="noreferrer">
          <img src={twitterIcon} alt="Twitter" />
        </a>
      </li>
    ) : null,
    linkedInUrl ? (
      <li key="linkedin">
        <a href={linkedInUrl} target="_blank" rel="noreferrer">
          <img src={linkedinIcon} alt="LinkedIn" />
        </a>
      </li>
    ) : null
  ].filter(l => l);

  const legalLinks = useMemo(() => {
    return legalFooterLinks.map(({ id, title }, i) => {
      return (
        <Link
          to={navigationMap.get(id)?.path}
          key={`legal-footer-link-${i}`}
        >
          { title?.title }
        </Link>
      );
    });
  }, [ legalFooterLinks, navigationMap ]);

  return (
    <>
      <footer>
        <div className="container footer__inner">
          <Link className="nav-logo" to="/" title={contentfulHomepage.title}>
            <img src={siteLogo.file.url} alt={contentfulHomepage.title} />
          </Link>

          <div className="newsletter-signup">
            <h4>{footerNewsletterSignupHeadline}</h4>
            <p>{footerNewsletterSignupText?.footerNewsletterSignupText}</p>
            <Link className="button" to={newsletterSignupPage.path}>{ footerNewsletterSignupButtonText || 'Sign up'}</Link>
          </div>

          <ul className="singletons">
            <li><Link to={resourcesPage.path}>{resourcesPage.title?.title}</Link></li>
            <li><Link to={aboutPage.path}>{aboutPage.title?.title}</Link></li>
            <li><Link to={careersPage.path}>{careersPage.title?.title}</Link></li>
            <li><Link to={contactUsPage.path}>{contactUsPage.title?.title}</Link></li>
          </ul>

          <ul className="solution-categories">{solutionsRendered}</ul>

          <div className="legal-links-container">
            { legalLinks }
          </div>

          <ul className="social-links">{socialLinks}</ul>
        </div>
      </footer>
      <p className="copyright">{copyright}</p>
    </>
  );
}
