import React from 'react';
import { graphql } from 'gatsby';
import RichText from '../rich-text';
import soundwaveImg from '../../images/soundwave.svg';
import './index.scss';

export default function FeatureModule ({
  heading, richText, foregroundImage, backgroundImage, showBackgroundSoundwave
}) {
  let background = [];

  if (showBackgroundSoundwave) {
    background.push(`bottom / 100% 85% no-repeat url(${soundwaveImg})`);
  }

  if (backgroundImage?.fixed?.src) {
    background.push(`center / cover no-repeat url(${backgroundImage.fixed.src})`);
  }

  background = background.join(', ');

  const headingRendered = heading?.heading ? ( <h4>{heading.heading}</h4> ) : null;

  const textRendered = richText?.raw ? (<RichText node={richText} />) : null;

  let textSectionRendered = null;
  if (background || headingRendered || textRendered) {
    textSectionRendered = (
      <div className="text">
        {headingRendered}
        {textRendered}
      </div>
    );
  }

  let foregroundImageRendered = null;
  let marginBottom = null;

  if (foregroundImage) {
    let height = null;

    if (headingRendered || textRendered) {
      height = `calc(${(foregroundImage.fixed.height / 2) / 20}rem - 4rem)`;
      marginBottom = `calc(${(foregroundImage.fixed.height / 2) / 20}rem + 4rem)`;
    }

    foregroundImageRendered = (
      <div className="foreground-image" style={{ height }}>
        <img
          src={foregroundImage.fixed.src}
          srcSet={foregroundImage.fixed.srcSet}
          alt={foregroundImage.description}
        />
      </div>
    );
  }

  return (
    <section className="feature-module" style={{ background, marginBottom }}>
      <div className="feature-module__inner container">
        {textSectionRendered}
        {foregroundImageRendered}
      </div>
    </section>
  );
}

export const query = graphql`
fragment featureModuleFields on ContentfulFeatureModule {
  showBackgroundSoundwave
  heading { heading }
  richText { raw }
  #text { raw }

  foregroundImage {
    description
    fixed(width: 900) {
      src, srcSet, height
    }
  }

  backgroundImage {
    description
    fixed(width: 1600) { src }
  }
}
`;
