import React from 'react';
import { graphql } from 'gatsby';
import { BackgroundImage } from '../../components/images';
import RichText from '../rich-text';
import './index.scss';

export default function BeyondZeroHarmModule ({
  heading, text, backgroundImage, quoteAttribution
}) {
  const attribution = (() => {
    if (!quoteAttribution) return null;
    return (
      <cite>
        <strong>{quoteAttribution.name?.name}</strong><br />
        {quoteAttribution.title?.title}
      </cite>
    );
  })();

  return (
    <section className="beyond-zero-harm-module">
      <BackgroundImage image={backgroundImage} colorWash={false} layout="fullWidth" />
      <div className="container beyond-zero-harm-module__inner">
        <h4>{heading?.heading}</h4>

        <div className="text">
          <RichText node={text} />
          {attribution}
        </div>
      </div>
    </section>
  );
}

export const query = graphql`
fragment beyondZeroHarmModuleFields on ContentfulBeyondZeroHarmModule {
  heading { heading }
  text { raw }

  backgroundImage {
    gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)

    #fixed(width: 1440, quality: 70, toFormat: JPG) {
    #  src, srcSet
    #}
  }

  quoteAttribution {
    name { name }
    title { title }
  }
}
`;
