import React, { createContext, useEffect, useState } from 'react';

export const ClickDimensionsContext = createContext({});

export function getTimeZoneName () {
  try {
    const result = (new Intl.DateTimeFormat()).resolvedOptions().timeZone;

    if (result) return result;
  } catch (e) {
    return null;
  }

  return null;
}

export default function ClickDimensions ({ children }) {
  const [ cdAnalytics, setCdAnalytics ] = useState(false);

  useEffect(() => {
    const cdAnalyticsScript = document.createElement('script');

    cdAnalyticsScript.src = 'https://analytics-eu.clickdimensions.com/ts.js';

    cdAnalyticsScript.addEventListener('load', () => {
      const cdScore = window.cdScore;
      const cda = new window.clickdimensions.Analytics('analytics-eu.clickdimensions.com');
      cda.setAccountKey('anXgr9GyEp0qpiMFAbLpCw');
      cda.setDomain('ndt-global.com');
      //eslint-disable-next-line eqeqeq
      cda.setScore(typeof (cdScore) == 'undefined' ? 0 : (cdScore == 0 ? null : cdScore));
      cda.trackPage();

      window.cdAnalytics = cda;
      setCdAnalytics(cda);
    });

    document.head.appendChild(cdAnalyticsScript);

    return () => {
      document.head.removeChild(cdAnalyticsScript);
    };
  }, []);

  return (
    <ClickDimensionsContext.Provider value={cdAnalytics}>
      {children}
    </ClickDimensionsContext.Provider>
  );
}
