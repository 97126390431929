import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import './index.scss';

// function fixImageUrls (obj) {
//   if (Array.isArray(obj)) {
//     for (let o of obj) {
//       fixImageUrls(o);
//     }
//
//     return obj;
//   }
//
//   if ('object' === typeof obj) {
//     for (let k in obj) {
//       if ('string' == typeof obj[k]) {
//         obj[k] = obj[k].replace(/^\/\/[^/]+/gm, 'https://www.ndt-global.com');
//       } else if ('object' == typeof obj[k]) {
//         fixImageUrls(obj[k]);
//       }
//     }
//
//     return obj;
//   }
//
//   throw new Error(`Invalid obj: ${JSON.stringify(obj)}`);
// }

export default function Img ({
  image,
  loading = 'lazy',
  ...props
}) {
  if (!image) return null;

  return (
    <GatsbyImage
      placeholder="blurred"
      {...props}
      image={getImage(image)}
      alt={image.description}
      loading={loading}
    />
  );
}

export function BackgroundImage ({
  image,
  colorWash = true,
  loading = 'lazy',
  ...props
}) {
  if (!image) return null;

  // fixImageUrls(image);

  const className = [
    props.className,
    colorWash ? 'color-wash-background': null
  ].filter(e => e).join(' ');

  return (
    <GatsbyImage
      role="presentation"
      alt=""
      placeholder="blurred"
      {...props}
      className={className}
      image={getImage(image)}
      loading={loading}
    />
  );
}

export function HeroBackgroundImage ({
  heroBackgroundImage,
  colorWash = true,
  overlay = false,
  ...props
}) {
  // fixImageUrls(heroBackgroundImage);

  let overlayRendered = null;
  if (overlay) {
    overlayRendered = (
      <div className="gatsby-image-overlay"></div>
    );
  }

  return (
    <>
      <BackgroundImage
        loading="eager"
        layout="fullWidth"
        {...props}
        image={heroBackgroundImage}
        colorWash={colorWash}
      />
      {overlayRendered}
    </>
  );
}

export const query = graphql`
fragment heroBackgroundImageFields on ContentfulAsset {
  gatsbyImageData(quality: 60, layout: FULL_WIDTH)
}

fragment pageOgImageFields on ContentfulAsset {
  fixed(width: 1080, toFormat: JPG, quality: 70) { src }
}

fragment imageFields on ContentfulAsset {
  description
  gatsbyImageData(quality: 80, placeholder: BLURRED)
}

`;
