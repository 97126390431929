import React, { useState } from 'react';
import RichText from '../rich-text';
import { graphql } from 'gatsby';
import { BackgroundImage } from '../../components/images';
import ReCaptcha, { validateForSubmit } from './recaptcha';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import countries from './countries.json';
import './index.scss';

const FORM_ENDPOINT = `${process.env.API_BASE}/contact`;

export default function ContactModule ({ heading, backgroundImage, successMessage }) {
  const [ showSuccessMessage, setShowSuccessMessage ] = useState(false);

  const handleSubmit = (ev) => {
    ev.preventDefault();

    const formEl = ev.target;

    async function _handleSubmit () {
      const recaptchaData = await validateForSubmit('submit_contact_form');

      const data = { ...recaptchaData };

      for (let el of formEl) {
        if (!el.name?.length || !el.value?.length) continue;
        data[el.name] = el.value;
      }

      const res = await fetch(FORM_ENDPOINT, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        referrerPolicy: 'no-referrer-when-downgrade',
        body: JSON.stringify(data)
      });

      if (!res.ok) {
        const errBody = await res.text();
        throw new Error(`Form submission failed ${res.status}: ${errBody}`);
      }

      setShowSuccessMessage(true);
    }

    _handleSubmit().catch(console.error);
  };

  const legalCheckbox = (
    <Checkbox
      name="contact-us-form-terms-accepted"
      color="secondary"
      required={true}
      value="Yes"
    />
  );

  const successMessageRendered = showSuccessMessage ? (
    <form className="success-message">
      <div className="heading-wrapper">
        <h2>{heading?.heading}</h2>
      </div>
      <RichText node={successMessage} />
    </form>
  ) : null;

  const form = showSuccessMessage ? null : (
    <form className="contact-module-form" onSubmit={handleSubmit}>
      <div className="heading-wrapper">
        <h2>{heading?.heading}</h2>
      </div>
      <div className="form-field-half-width">
        <TextField
          id="contact-us-form-first-name"
          label="Your first name"
          name="contact-us-form-first-name"
          color="secondary"
          required={true}
          fullWidth={true}
        />
      </div>
      <div className="form-field-half-width">
        <TextField
          id="contact-us-form-last-name"
          label="Your last name"
          name="contact-us-form-last-name"
          color="secondary"
          required={true}
          fullWidth={true}
        />
      </div>
      <TextField
        id="contact-us-form-company"
        label="Your company"
        name="contact-us-form-company"
        color="secondary"
        required={true}
        fullWidth={true}
      />
      <TextField
        id="contact-us-form-email"
        label="Your email address"
        name="contact-us-form-email"
        color="secondary"
        required={true}
        fullWidth={true}
        type="email"
      />
      <div className="form-field-half-width">
        <TextField
          id="contact-us-form-country"
          label="Your country"
          name="contact-us-form-country"
          color="secondary"
          required={true}
          fullWidth={true}
          select
          SelectProps={{ native: true }}
        >
          <option key="null"></option>
          {countries.map((c, i) => (<option key={i}>{c}</option>))}
        </TextField>
      </div>
      <div className="form-field-half-width">
        <TextField
          id="contact-us-form-phone"
          label="Your phone number"
          color="secondary"
          name="contact-us-form-phone"
          required={true}
          fullWidth={true}
          type="tel"
        />
      </div>
      <TextField
        id="contact-us-form-message"
        label="Your message"
        name="Tell us about your challenges"
        color="secondary"
        required={true}
        fullWidth={true}
        multiline
      />
      <TextField
        id="contact-us-form-how-did-you-hear"
        label="How did you hear about NDT Global?"
        name="contact-us-form-how-did-you-hear"
        color="secondary"
        required={true}
        fullWidth={true}
        select
        SelectProps={{ native: true }}
      >
        <option></option>
        <option>Google</option>
        <option>Bing</option>
        <option>LinkedIn</option>
        <option>Instagram</option>
        <option>Website</option>
        <option>Word of mouth</option>
        <option>Other</option>
      </TextField>
      <FormControlLabel
        control={legalCheckbox}
        label="By checking this box, you are agreeing that NDT Global is allowed to contact you regarding your inquiry."
        required={true}
        color="secondary"
      />

      <button type="submit">Submit</button>
    </form>
  );

  return (
    <ReCaptcha>
      <section className="contact-module">
        <BackgroundImage image={backgroundImage} layout="fullWidth"/>
        {
          heading ? (
            <div className="mobile-text">
              <h2>{ heading.heading }</h2>
            </div>
          ) : ''
        }
        {successMessageRendered}
        {form}
      </section>
    </ReCaptcha>
  );
}

export const query = graphql`
fragment contactModuleFields on ContentfulContactModule {
  id
  heading { heading }
  successMessage { raw }

  backgroundImage {
    gatsbyImageData(
      resizingBehavior: FILL
      quality: 60
      placeholder: BLURRED
      layout: FULL_WIDTH
    )
  }
}
`;
