import React, { useState } from 'react';
import SearchForm from './search-form';
import { Link } from 'gatsby';
import Chevron from '../chevron';
import MobileNavSecondary from './mobile-nav-secondary';
import './mobile-nav.scss';

export default function MobileNav ({
  active,
  resourcesPage,
  aboutPage,
  careersPage,
  searchPage,
  solutions
}) {
  const [ activeAccordion, setActiveAccordion ] = useState(null);

  const navItems = solutions.map((solution, i) => {
    const { title, contentfulchildren, id } = solution;

    return (
      <li key={`mobile-nav-item-${i}`}>
        <div
          className="nav-item-heading"
          onClick={() => setActiveAccordion(id)}
        >
          <span>{ title?.title }</span>
          <Chevron dir="right" />
        </div>
        <MobileNavSecondary
          parentItem={solution}
          items={contentfulchildren}
          active={activeAccordion === id}
          setActive={setActiveAccordion}
        />
      </li>
    );
  });

  return (
    <div className={`mobile-nav ${active ? 'active' : ''}`}>
      <div className="mobile-nav__inner">
        <SearchForm searchPage={searchPage} autoFocus={false} />
        <div className="mobile-nav__main-nav">
          <ul className="mobile-nav-accordions">
            { navItems }
          </ul>
        </div>
        <ul className="mobile-nav__singletons">
          <li>
            <Link to={resourcesPage.path}>{resourcesPage.title.title}</Link>
          </li>
          <li>
            <Link to={aboutPage.path}>{aboutPage.title.title}</Link>
          </li>
          <li>
            <Link to={careersPage.path}>{careersPage.title.title}</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
