import React from 'react';
import { graphql } from 'gatsby';
import Img from '../../components/images';
import CtaArrowsModule from '../cta-arrows-module';
import Video from './video';
import './index.scss';

export default function ImageVideoModule ({
  asset, spanFullViewport, ctaModule, videoPosterImage
}) {
  let className = 'image-video-module';

  if (spanFullViewport) className += ' full-viewport-width';

  const ctaArrowsModuleRendered = (() => {
    if (!ctaModule?.ctaTarget || !ctaModule?.text) return null;

    className += ' has-arrows';

    return ( <CtaArrowsModule {...ctaModule} /> );
  })();

  let assetRendered = null;

  if (asset?.file?.contentType.startsWith('video/')) {
    assetRendered = ( <Video asset={asset} poster={videoPosterImage?.fixed?.src}/> );
  } else {
    assetRendered = (
      <Img image={asset} /> );
  }

  return (
    <section className={className}>
      {assetRendered}
      {ctaArrowsModuleRendered}
    </section>
  );
}

export const query = graphql`
fragment imageVideoModuleFields on ContentfulImageVideoModule {
  id, name, spanFullViewport

  asset {
    ...imageFields

    description

    file {
      url
      contentType
    }
  }

  videoPosterImage {
    fixed(width: 1600, toFormat: JPG, quality: 80) {
      src
    }
  }

  ctaModule {
    ...ctaArrowsModuleFields
  }
}
`;
