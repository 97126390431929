import React from 'react';
import { graphql } from 'gatsby';
import Img from '../../components/images';
import RichText from '../rich-text';
import CtaButton from '../cta-button';
import './index.scss';

export default function PrimaryContentModule ({
  heading, ctaTarget, showBackgroundWaveform, leadParagraph, text, sideForegroundImage,
  sideBackgroundImage, sideImagesPosition, ctaText
}) {
  let className = 'primary-content-module';

  if (/left/i.test(sideImagesPosition) && (sideForegroundImage || sideBackgroundImage)) {
    className += ' images-left';
  } else if (sideForegroundImage || sideBackgroundImage) {
    className += ' images-right';
  }

  if (showBackgroundWaveform) {
    className += ' show-waveform';
  }

  const headingRendered = heading?.heading ? (<h2>{heading?.heading}</h2>) : null;

  const leadParagraphRendered = (() => {
    if (!leadParagraph?.raw) return null;
    return (
      <div className="lead">
        <RichText node={leadParagraph} />
      </div>
    );
  })();

  let sideForegroundImageRendered = (() => {
    if (!sideForegroundImage) return null;

    let style = {};

    const width = sideForegroundImage.file?.details?.image?.width;

    if (width && width < 360) {
      style.maxWidth = `${width/20}rem`;
    }

    return (
      <Img imgClassName="foreground" style={style} image={sideForegroundImage} />
    );
  })();

  let sideBackgroundImageRendered = (() => {
    if (!sideBackgroundImage) return null;

    let style = {};

    const width = sideBackgroundImage.file?.details?.image?.width;

    if (width && width < 500) {
      style.maxWidth = `${width/20}rem`;
    }

    return (
      <Img imgClassName="background" imgStyle={style} image={sideBackgroundImage} />
    );
  })();

  return (
    <section className={className}>
      <div className="container primary-content-module__inner">
        {headingRendered}
        {leadParagraphRendered}

        <div className="text">
          <RichText node={text} />

          <CtaButton ctaText={ctaText} ctaTarget={ctaTarget} />
        </div>

        <div className="bg-fg-container">
          {sideBackgroundImageRendered}
          {sideForegroundImageRendered}
        </div>
      </div>
    </section>
  );
}

export const query = graphql`
fragment primaryContentModuleFields on ContentfulPrimaryContentModule {
  ctaText, sideImagesPosition, showBackgroundWaveform
  heading { heading }
  leadParagraph { raw }
  text { raw }

  ctaTarget {
    __typename
    ... on ContentfulAboutPage { id }
    ... on ContentfulCareersPage { id }
    ... on ContentfulContactUsPage { id }
    ... on ContentfulEvent { id }
    ... on ContentfulGenericPage { id }
    ... on ContentfulNewsletterSignupPage { id }
    ... on ContentfulResource { id }
    ... on ContentfulResourcesPage { id }
    ... on ContentfulSolution { id }
    ... on ContentfulSolutionCategory { id }
  }

  sideForegroundImage {
    ...imageFields

    file {
      details {
        image { width }
      }
    }
  }

  sideBackgroundImage {
    ...imageFields

    file {
      details {
        image { width }
      }
    }
  }
}
`;
