import React from 'react';
import { graphql } from 'gatsby';
import { BackgroundImage } from '../../components/images';
import RichText from '../rich-text';
import CtaButton from '../cta-button';
import './index.scss';

export default function SideBlockModule ({
  heading, text, ctaText, ctaTarget, backgroundImage, leadParagraph, blockPosition, hideHeading
}) {
  let className = 'side-block-module';

  if (/left/i.test(blockPosition)) {
    className += ' block-left';
  }

  return (
    <section className={className}>
      <BackgroundImage image={backgroundImage} colorWash={false} layout="fullWidth"/>
      <div className="container side-block-module__inner">
        <h2>{hideHeading ? null : heading?.heading}</h2>
        <div className="side-block">
          <div className="lead"><RichText node={leadParagraph} /></div>
          <RichText node={text} />
          <CtaButton ctaText={ctaText} ctaTarget={ctaTarget} />
        </div>
      </div>
    </section>
  );
}

export const query = graphql`
fragment sideBlockModuleFields on ContentfulSideBlockModule {
  ctaText, blockPosition, hideHeading
  heading { heading }
  text { raw }
  leadParagraph { raw }

  backgroundImage {
    gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
  }

  ctaTarget {
    __typename
    ... on ContentfulAboutPage { id }
    ... on ContentfulCareersPage { id }
    ... on ContentfulContactUsPage { id }
    ... on ContentfulEvent { id }
    ... on ContentfulGenericPage { id }
    ... on ContentfulNewsletterSignupPage { id }
    ... on ContentfulResource { id }
    ... on ContentfulResourcesPage { id }
    ... on ContentfulSolution { id }
    ... on ContentfulSolutionCategory { id }
  }
}
`;
