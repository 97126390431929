/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import RichText from '../rich-text';
//import './index.scss';

export default function ButtonLinkModule({ text, targetUrl }
) {


return (
<div className=" desktop-serch-input">

        <a href={targetUrl}><button className="Search-Career-btn">{text.text}</button></a>

            <div className="mobile-serch-input">
                <div className="col-md-12 col-sm-12 col-lg-12">
                    <div className="input-group md-form form-sm form-2 pl-0">
                        <input className="form-control my-0 py-1 amber-border" type="text" placeholder="Search" aria-label="Search" />
                        <div className="input-group-append">
                            <span className="input-group-text" id="basic-text1">
                            <i className="fas fa-search text-grey" aria-hidden="true"></i>
                          </span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
);
}

export const query = graphql`
fragment buttonLinkFields on ContentfulButtonLink {
  style, targetUrl
  text { text }
}
`;
