import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
//import './index.scss';

export default function RichTextImage ({ node }) {
  const data = useStaticQuery(graphql`
    query AssetQuery {
      allContentfulAsset(
        filter: {file: {contentType: {glob: "image/*"}, details: {size: {gt: 0}}}}
      ) {
        nodes {
          contentful_id
          description
          gatsbyImageData(quality: 70)
        }
      }
    }
  `);

  const assetId = node.data?.target?.sys?.id;
  const asset = data.allContentfulAsset.nodes.find(n => n.contentful_id === assetId );

  if (!asset) {
    console.warn(`No asset: ${JSON.stringify({ node })}`);
    return null;
  }

  const image = getImage(asset);

  if (!image) {
    console.error(`No image: ${JSON.stringify({ node })}`);
    return null;
  }

  return ( <GatsbyImage image={image} alt={asset.description} /> );
}
