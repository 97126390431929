import React from 'react';
import { graphql } from 'gatsby';
import Img from '../../components/images';
import imagesArrowImg from '../../images/images-arrow.svg';
import './index.scss';

export default function ImagesWithArrowModule ({ foregroundImage, backgroundImage }) {
  return (
    <section className="images-with-arrow-module">
      <div className="container images-with-arrow-module__inner">
        <Img image={foregroundImage} className="foreground-image" />
        <Img image={backgroundImage} className="background-image" />
        <img
          src={imagesArrowImg}
          className="images-arrow"
          alt=""
          role="presentation"
        />
      </div>
    </section>
  );
}

export const query = graphql`
fragment imagesWithArrowModuleFields on ContentfulImagesWithArrowModule {
  foregroundImage {
    ...imageFields
  }

  backgroundImage {
    ...imageFields
  }
}
`;
