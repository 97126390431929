import React from 'react';
import { graphql } from 'gatsby';
//import './index.scss';

export default function HorizontalLinksModule ({ links }) {
  return (
    <section className="horizontal-links-module">
      {/*JSON.stringify(links)*/}
    </section>
  );
}

export const query = graphql`
fragment horizontalLinksModuleFields on ContentfulHorizontalLinksModule {
  links {
    __typename
    ... on ContentfulButtonLink {
      ...buttonLinkFields
    }
    ... on ContentfulButtonLinkInternal {
      ...buttonLinkInternalFields
    }
  }
}
`;
