import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { NavigationContext } from '../navigation';
import './index.scss';

export default function CtaButton ({ className = 'cta-button button', ctaText, ctaTarget }) {
  const navigationMap = useContext(NavigationContext);

  if (!ctaTarget) return null;

  if ('string' === typeof ctaTarget) {
    return (
      <a className={className} href={ctaTarget} target="_blank" rel="noreferrer">{ctaText}</a>
    );
  }

  const href = navigationMap.get(ctaTarget?.id)?.path;
  return (
    <Link className={className} to={href}>{ctaText}</Link>
  );
}
