import React, { useContext, useState } from 'react';
import { Link } from 'gatsby';
import chevronRightIcon from '../../images/icon-chevron-right-thick.svg';
import { NavigationContext } from './index';

export default function ExtendedMenuLevel ({ className, parentItem, handleOpenNextLevel }) {
  const navigationMap = useContext(NavigationContext);
  const [ itemOpen, setItemOpen ] = useState(false);

  if (!parentItem) return null;

  const items = parentItem.contentfulchildren.map((c, i) => {
    if (c.contentfulchildren?.length && handleOpenNextLevel) {
      const cClassName = itemOpen === c ? 'active' : '';

      const handleClick = (...args) => {
        handleOpenNextLevel(c, ...args);
        setItemOpen(c);
      };

      return (
        <li key={i}>
          <a
            href={navigationMap.get(c.id)?.path}
            onClick={handleClick}
            className={cClassName}
          >
            {c.title?.title}
            <img src={chevronRightIcon} alt="" role="presentation" />
          </a>
        </li>
      );
    } else if (c.contentfulchildren?.length && !handleOpenNextLevel) {
      console.warn(`Navigation too deep for ${JSON.stringify(c)}`);
    }

    return (
      <li key={i}>
        <Link to={navigationMap.get(c.id)?.path}>{c.title?.title}</Link>
      </li>
    );
  });

  return (
    <ul className={className}>
      <li>
        <Link to={navigationMap.get(parentItem.id)?.path}>
          All {parentItem.title?.title}
        </Link>
      </li>
      {items}
    </ul>
  );
}
