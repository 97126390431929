import React, { createContext, useContext, useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { Link } from 'gatsby';
import searchIcon from '../../images/icon-search.svg';
import menuIcon from '../../images/icon-menu.svg';
import closeIcon from '../../images/icon-close.svg';
import SearchForm from './search-form';
import MobileNav from './mobile-nav';
import ExtendedMenu from './extended-menu';
import './index.scss';

export const NavigationContext = createContext({});

export default function Navigation ({
  siteLogo, contentfulHomepage, primarySolutionCategories, resourcesPage, aboutPage,
  careersPage, contactUsPage, searchPage
}) {
  const navigationMap = useContext(NavigationContext);
  const ref = useRef(null);
  const searchButtonRef = useRef(null);
  const searchFormRef = useRef(null);
  const [ navigationOpen, setNavigationOpen ] = useState(false);
  const [ searchOpen, setSearchOpen ] = useState(false);
  const [ extendedMenuOpen, setExtendedMenuOpen ] = useState(false);

  const toggleNavigation = useCallback((ev) => {
    ev.preventDefault();

    if (!navigationOpen) setSearchOpen(false);

    setNavigationOpen(!navigationOpen);
  }, [ navigationOpen ]);

  const mobileNavigationToggle = useMemo(() => {
    let icon = ( <img className="menu-icon" src={menuIcon} alt="Open navigation menu" /> );

    if (navigationOpen) {
      icon = ( <img className="close-icon" src={closeIcon} alt="Close navigation menu" /> );
    }

    return ( <a className="nav-button" href="#" onClick={toggleNavigation}>{icon}</a> );
  }, [ navigationOpen, toggleNavigation ]);

  const toggleSearch = useCallback((ev) => {
    ev.preventDefault();

    if (!searchOpen) setExtendedMenuOpen(false);

    setSearchOpen(!searchOpen);
  }, [ searchOpen ]);

  const searchToggle = useMemo(() => {
    let icon = ( <img className="search-icon" src={searchIcon} alt="Open search" /> );

    if (searchOpen) {
      icon = ( <img className="close-icon" src={closeIcon} alt="Close search" /> );
    }

    return (
      <a
        className="search-button"
        href="#"
        onClick={toggleSearch}
        ref={searchButtonRef}
      >
        {icon}
      </a>
    );
  }, [ searchOpen, toggleSearch ]);

  const searchForm = useMemo(() => {
    return searchOpen ? (<SearchForm searchPage={searchPage} ref={searchFormRef} />) : null;
  }, [ searchOpen, searchPage ]);

  useEffect(() => {
    if (!searchFormRef || !searchButtonRef) return;

    function handleMouseDown (ev) {
      if (searchFormRef?.current?.contains(ev.target)) return;
      if (searchButtonRef?.current?.contains(ev.target)) return;
      setSearchOpen(false);
    }

    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [ searchFormRef, searchButtonRef ]);

  const solutionsRendered = useMemo(() => {
    return primarySolutionCategories.map((solutionCategory, i) => {
      const categoryPath = navigationMap.get(solutionCategory.id)?.path;

      let className = '';

      if (extendedMenuOpen === solutionCategory) {
        className = 'active';
      }

      const handleClick = (ev) => {
        ev.preventDefault();

        if (solutionCategory === extendedMenuOpen) {
          setExtendedMenuOpen(false);
        } else {
          setExtendedMenuOpen(false);
          requestAnimationFrame(() => setExtendedMenuOpen(solutionCategory));
        }
      };

      return (
        <li key={i}>
          <a href={categoryPath} onClick={handleClick} className={className}>
            {solutionCategory.title?.title}
          </a>
        </li>
      );
    });
  }, [ navigationMap, primarySolutionCategories, extendedMenuOpen ]);

  const extendedMenuRendered = useMemo(() => {
    if (!extendedMenuOpen) return null;

    return (
      <ExtendedMenu solutionCategory={extendedMenuOpen} contactUsPage={contactUsPage} />
    );
  }, [ extendedMenuOpen, contactUsPage ]);

  useEffect(() => {
    if (!ref) return;

    function handleMouseDown (ev) {
      if (ref?.current?.contains(ev.target)) return;
      setExtendedMenuOpen(null);
    }

    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [ ref ]);

  return (
    <nav className="navbar" ref={ref}>
      <div className="container navbar__inner">
        <Link className="nav-logo" to="/" title={contentfulHomepage.title}>
          <img src={siteLogo.file.url} alt={contentfulHomepage.title} />
          {/* <Logo /> */}
        </Link>

        <ul className="solution-categories">{solutionsRendered}</ul>

        <ul className="singletons">
          <li><Link to={resourcesPage.path}>{resourcesPage.title.title}</Link></li>
          <li><Link to={aboutPage.path}>{aboutPage.title.title}</Link></li>
          <li><Link to={careersPage.path}>{careersPage.title.title}</Link></li>
        </ul>

        <Link className="button" to={contactUsPage.path}>
          <span>{contactUsPage.title.title}</span>
          {/*TODO <img src={contactUsIcon} alt={contactUsPage.title.title} />*/}
        </Link>

        {searchToggle}

        {mobileNavigationToggle}

        {searchForm}

        <MobileNav
          active={navigationOpen}
          resourcesPage={resourcesPage}
          aboutPage={aboutPage}
          careersPage={careersPage}
          searchPage={searchPage}
          solutions={primarySolutionCategories}
        />
      </div>

      {extendedMenuRendered}
    </nav>
  );
}
