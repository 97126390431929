/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


import React,  { useMemo} from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import RichText from '../rich-text';
//import './index.scss';

export default function TwoColumnModule( { heading, leftIcon, leftHeading,leftText,rightIcon,rightHeading,rightText }
) {

    return (

     <section className="invest-you-wrap">
        <h6>{heading.heading}</h6>
        <div className="invest-content">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div className="col-sm-2 col-md-2 col-lg-2 talent-development-wrap">
                    <div className="talent-development-img">
                        <img src={leftIcon.file.url} alt="" role="presentation" />
                    </div>
                </div>
                <div className="col-sm-10 col-lg-10 col-md-10 col-xl-10 remove-padding">
                    <div className="talent-development-content">
                        <h4>{leftHeading.leftHeading}</h4>
                        <p><RichText node={leftText} /></p>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div className="col-sm-2 col-md-2 col-lg-2 compensation-benefits-wrap">
                    <div className="compensation-benefits-img">
                        <img src={rightIcon.file.url} alt="" role="presentation" />
                    </div>
                </div>
                <div className="col-sm-10 col-lg-10 col-md-10 col-xl-10 remove-padding">
                    <div className="compensation-benefits-content">
                        <h4>{rightHeading.rightHeading}</h4>
                        <p><RichText node={rightText} /></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}

export const query = graphql`
fragment twoColumnModuleFields on ContentfulTwoColumnModule {
  heading { heading }
  leftHeading { leftHeading }
  rightHeading { rightHeading }
  leftText { raw }
  rightText { raw }

  leftIcon {
    file { url }
  }

  rightIcon {
    file { url }
  }
}
`;
