import React, { createElement as cE, useContext } from 'react';
import { Link } from 'gatsby';
import { MARKS, INLINES, BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { NavigationContext } from '../navigation';
import RichTextImage from './image';
//import './index.scss';

const HyperLink = ({ node, children }) => {
  const { data } = node;

  if (data.uri.startsWith('/')) {
    return ( <Link to={data.uri}>{children}</Link> );
  }

  return ( <a href={data.uri} target="_blank" rel="noreferrer">{children}</a> );
};

const EntryHyperlink = ({ node, children }) => {
  const navigationMap = useContext(NavigationContext);

  const targetPath = navigationMap.get(node.data?.target?.sys?.id)?.path ?? '#';

  return (
    <Link to={targetPath}>
      {children}
    </Link>
  );
};

// See https://github.com/contentful/rich-text
// and https://www.contentful.com/developers/docs/tutorials/general/rich-text-and-gatsby/
const options = {
  renderMark: {
    [MARKS.BOLD]: text => ( <strong>{text}</strong> ),
    [MARKS.ITALIC]: text => ( <em>{text}</em> ),
    [MARKS.UNDERLINE]: text => ( <span style={{ textDecoration: 'underline' }}>{text}</span> )
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => cE(HyperLink, { node }, children),
    [INLINES.ENTRY_HYPERLINK]: (node, children) => cE(EntryHyperlink, { node }, children),
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => cE(RichTextImage, { node }, children)
  }
};

export default ({ node }) => {
  if (!node?.raw) return null;
  return renderRichText(node, options);
};

export const renderFlatRichText = ({ node }) => {
  if (!node?.raw) return null;
  return renderRichText(node, {
    renderMark: {
      [MARKS.BOLD]: text => text,
      [MARKS.ITALIC]: text => text,
      [MARKS.UNDERLINE]: text => text
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (n, children) => children,
      [BLOCKS.HEADING_1]: (n, children) => children,
      [BLOCKS.HEADING_2]: (n, children) => children,
      [BLOCKS.HEADING_3]: (n, children) => children,
      [BLOCKS.HEADING_4]: (n, children) => children,
      [BLOCKS.HEADING_5]: (n, children) => children,
      [BLOCKS.HEADING_6]: (n, children) => children,
      [INLINES.HYPERLINK]: (n, children) => children,
      [INLINES.ENTRY_HYPERLINK]: (n, children) => children
    }
  });
};
