import React, { createElement } from 'react';

export { default as Layout } from './layout';

import { default as ContentfulContactModule } from './contact-module';
import { default as ContentfulFeatureModule } from './feature-module';
import { default as ContentfulPrimaryContentModule } from './primary-content-module';
import { default as ContentfulImageVideoModule } from './image-video-module';
import { default as ContentfulImagesWithArrowModule } from './images-with-arrow-module';
import { default as ContentfulFeaturedSolutionsModule } from './featured-solutions-module';
import { default as ContentfulSideBlockModule } from './side-block-module';
import { default as ContentfulBeyondZeroHarmModule } from './beyond-zero-harm-module';
import { default as ContentfulFeaturedResourcesModule } from './featured-resources-module';
import { default as ContentfulQuoteModule } from './quote-module';
import { default as ContentfulTwoColumnModule } from './two-column-module';
import { default as ContentfulArticleContentModule } from './article-content-module';
import { default as ContentfulCtaArrowsModule } from './cta-arrows-module';
import { default as ContentfulDownloadModule } from './download-module';
import { default as ContentfulLeadershipModule } from './leadership-module';
import { default as ContentfulButtonLink } from './button-link';
import { default as ContentfulHorizontalLinksModule } from './horizontal-links-module';

export const CONTENT_MODULES = {
  ContentfulContactModule,
  ContentfulFeatureModule,
  ContentfulPrimaryContentModule,
  ContentfulImageVideoModule,
  ContentfulImagesWithArrowModule,
  ContentfulFeaturedSolutionsModule,
  ContentfulSideBlockModule,
  ContentfulBeyondZeroHarmModule,
  ContentfulFeaturedResourcesModule,
  ContentfulQuoteModule,
  ContentfulTwoColumnModule,
  ContentfulArticleContentModule,
  ContentfulCtaArrowsModule,
  ContentfulDownloadModule,
  ContentfulLeadershipModule,
  ContentfulButtonLink,
  ContentfulHorizontalLinksModule
};

export function renderModules (modules) {
  const rendered = modules?.map((m, i) => {
    let mod = CONTENT_MODULES[m.__typename];

    if (!mod) throw new Error(`Invalid module type: ${m.__typename}`);

    return createElement(mod, { key: i, ...m });
  }) ?? null;

  return ( <>{rendered}</> );
}
