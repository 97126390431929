import React, { useContext } from 'react';
import { graphql, Link } from 'gatsby';
import RichText from '../rich-text';
import iconPlus from '../../images/icon-plus.svg';
import { NavigationContext } from '../navigation';
import CtaButton from '../cta-button';
import './index.scss';

export default function LeadershipModule ({
  heading, ctaTarget, leadText, text, ctaText, persons
}) {
  const navigationMap = useContext(NavigationContext);

  const cards = persons.map((person, i) => {
    const background = [
      'linear-gradient(to bottom, rgba(23, 24, 26, 0) 40%, rgba(23, 24, 26, 0.5) 100%)',
      `center / cover no-repeat url(${person.image?.fixed?.src})`
    ].join(', ');

    const linkTo = navigationMap.get(person.page?.id)?.path;

    let onClickNoLink = null;
    if (!linkTo) {
      onClickNoLink = ev => ev.preventDefault();
    }

    // const className = 'card-washed' + (linkTo ? '' : ' no-link');

    const linkBody = (
      <>
        <div
          className="color-wash-background"
          style={{ background }}
        ></div>
        <h6 className="card-sq-category">{person.title?.title}</h6>
        <h5 className="card-sq-heading">{person.name?.name}</h5>
        <img className="corner-icon" src={iconPlus} alt="" role="presentation" />
      </>
    );

    if (linkTo) {
      return (
        <Link className="card-washed" to={linkTo} key={i} onClick={onClickNoLink}>
          {linkBody}
        </Link>
      );
    }

    return ( <a className="card-washed" key={i}>{linkBody}</a> );
  });

  return (
    <section className="leadership-module">
      <h2>{heading?.heading}</h2>
      <div className="leadership-module-grid">
        <aside>
          <div className="lead"><p>{leadText?.leadText}</p></div>
          <RichText node={text} />
          <CtaButton ctaText={ctaText} ctaTarget={ctaTarget} />
        </aside>
        <div className="leadership-cards">{cards}</div>
        <CtaButton className="mobile-cta cta-button button" ctaText={ctaText} ctaTarget={ctaTarget} />
      </div>
    </section>
  );
}

export const query = graphql`
fragment leadershipModuleFields on ContentfulLeadershipModule {
  ctaText
  heading { heading }
  leadText { leadText }
  text { raw }

  ctaTarget {
    __typename
    ... on ContentfulAboutPage { id }
    ... on ContentfulCareersPage { id }
    ... on ContentfulContactUsPage { id }
    ... on ContentfulEvent { id }
    ... on ContentfulGenericPage { id }
    ... on ContentfulNewsletterSignupPage { id }
    ... on ContentfulResource { id }
    ... on ContentfulResourcesPage { id }
    ... on ContentfulSolution { id }
    ... on ContentfulSolutionCategory { id }
  }

  persons {
    name { name }
    title { title }
    page { id }
    image {
      fixed(height: 300, width: 300, cropFocus: FACE, resizingBehavior: FILL, quality: 90, toFormat: JPG) {
        src, srcSet
      }
    }
  }
}
`;
