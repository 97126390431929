import React from 'react';
import { graphql } from 'gatsby';
import RichText from '../rich-text';
import CtaButton from '../cta-button';
import './index.scss';

export default function DownloadModule ({ leadParagraph, text, previewImage, asset }) {
  let filesize = ( asset?.file?.details?.size ?? 0 ) / 1000;
  let filesizeUnit = 'KB';

  if (filesize > 1000) {
    filesize = filesize / 1000;
    filesizeUnit = 'MB';
  }

  if (filesize > 1000) {
    filesize = filesize / 1000;
    filesizeUnit = 'GB';
  }

  //eslint-disable react/jsx-no-target-blank
  return (
    <section className="download-module">
      <div className="download-description">
        <div className="lead"><RichText node={leadParagraph} /></div>
        <div className="text"><RichText node={text} /></div>
      </div>

      <div className="download-doc">
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href={asset?.file?.url} target="_blank">
          <img
            src={previewImage?.fixed?.src}
            srcSet={previewImage?.fixed?.srcSet}
            className="download-thumb"
            alt=""
            role="presentation"
          />
        </a>
        <CtaButton ctaText="Download PDF" ctaTarget={asset?.file?.url} />
        <span className="download-size">{Math.round(filesize)} {filesizeUnit}</span>
      </div>
    </section>
  );
}

export const query = graphql`
fragment downloadModuleFields on ContentfulDownloadModule {
  text { raw }
  leadParagraph { raw }

  asset {
    file {
      url
      details {
        size
      }
    }
  }

  previewImage {
    fixed(width: 500, quality: 90, toFormat: JPG) {
      src, srcSet
    }
  }
}
`;
