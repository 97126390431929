import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import Footer from '../footer';
import Navigation, { NavigationContext } from '../navigation';
import ClickDimensions from '../click-dimensions';
//import './index.scss';

function slugify (str) {
  return str.toLowerCase().replace(/[^a-z0-9]+/g, '-');
}

function buildNavigationMap ({
  contentfulHomepage, allContentfulSolution, allContentfulSolutionCategory, contentfulResourcesPage,
  contentfulAboutPage, contentfulCareersPage, contentfulContactUsPage, contentfulSearchPage,
  contentfulNewsletterSignupPage, allContentfulEvent, allContentfulGenericPage,
  allContentfulResource, allContentfulLocation
}) {
  const result = new Map();

  contentfulHomepage.path = '/';
  result.set(contentfulHomepage.path, contentfulHomepage);
  result.set(contentfulHomepage.id, contentfulHomepage);
  result.set(contentfulHomepage.contentful_id, contentfulHomepage);

  const singletons = [ contentfulResourcesPage, contentfulAboutPage, contentfulCareersPage,
    contentfulContactUsPage, contentfulSearchPage, contentfulNewsletterSignupPage ];

  for (let page of singletons) {
    if (!page.slug) {
      console.warn(`Slug is missing: ${JSON.stringify(page)}`);
      continue;
    }
    page.path = `/${page.slug}/`;
    result.set(page.path, page);
    result.set(page.id, page);
    result.set(page.contentful_id, page);
  }

  for (let page of allContentfulSolutionCategory.nodes) {
    if (!page.slug) {
      console.warn(`Slug is missing: ${JSON.stringify(page)}`);
      continue;
    }
    page.path = `/${page.slug}/`;
    result.set(page.path, page);
    result.set(page.id, page);
    result.set(page.contentful_id, page);
  }

  for (let page of allContentfulSolution.nodes) {
    if (!page.slug || !page.solutionCategory?.slug) {
      console.warn(`Solution category or slug is missing: ${JSON.stringify(page)}`);
      continue;
    }
    page.path = `/${page.solutionCategory.slug}/${page.slug}/`;
    result.set(page.path, page);
    result.set(page.id, page);
    result.set(page.contentful_id, page);
  }

  for (let page of allContentfulResource.nodes) {
    if (!page.slug || !page.category) {
      console.warn(`Slug or category is missing: ${JSON.stringify(page)}`);
      continue;
    }
    page.path = `${contentfulResourcesPage.path}${slugify(page.category)}/${page.slug}/`;
    result.set(page.path, page);
    result.set(page.id, page);
    result.set(page.contentful_id, page);
  }

  for (let page of allContentfulEvent.nodes) {
    if (!page.slug) {
      console.warn(`Slug is missing: ${JSON.stringify(page)}`);
      continue;
    }
    page.path = `${contentfulResourcesPage.path}event/${page.slug}/`;
    result.set(page.path, page);
    result.set(page.id, page);
    result.set(page.contentful_id, page);
  }

  for (let page of allContentfulGenericPage.nodes) {
    if (!page.slug) {
      console.warn(`Slug is missing: ${JSON.stringify(page)}`);
      continue;
    }
    page.path = `/${page.slug}/`;
    result.set(page.path, page);
    result.set(page.id, page);
    result.set(page.contentful_id, page);
  }

  for (let page of allContentfulLocation.nodes) {
    if (!page.slug) {
      console.warn(`Slug is missing: ${JSON.stringify(page)}`);
      continue;
    }
    page.path = `${contentfulContactUsPage.path}${page.slug}/`;
    result.set(page.path, page);
    result.set(page.id, page);
    result.set(page.contentful_id, page);
  }

  return result;
}

export default function Layout ({ currentPath, children }) {
  const data = useStaticQuery(graphql`
  query LayoutQuery {
    contentfulGlobalOptions {
      siteTitle
      siteLogo {
        file { url }
      }

      primarySolutionCategories {
        id, contentful_id, slug, navigationContactLinkText
        title { title }
        description { description }

        featuredContent {
          __typename
          ... on ContentfulResource {
            category
            id, contentful_id
            shortTitle
            cardBackgroundImage {
              fixed(cropFocus: CENTER, height: 300, width: 300, resizingBehavior: FILL) {
                src, srcSet
              }
            }
          }
          ... on ContentfulSolution {
            id, contentful_id
            shortTitle
            cardBackgroundImage {
              fixed(cropFocus: CENTER, height: 300, width: 300, resizingBehavior: FILL) {
                src, srcSet
              }
            }
          }
        }

        contentfulchildren {
          __typename
          ... on ContentfulSolutionCategory {
            id, contentful_id, slug
            title { title }
            contentfulchildren {
              __typename
              ... on ContentfulSolution {
                ...navContentfulSolution
              }
              ... on ContentfulSolutionCategory {
                id, contentful_id
              }
            }
          }
          ... on ContentfulSolution {
            ...navContentfulSolution
          }
        }
      }
    }

    contentfulHomepage { id, contentful_id, title }
    contentfulResourcesPage { id, contentful_id, slug, title { title } }
    contentfulAboutPage { id, contentful_id, slug, title { title } }
    contentfulCareersPage { id, contentful_id, slug, title { title } }
    contentfulContactUsPage { id, contentful_id, slug, title { title } }
    contentfulSearchPage { id, contentful_id, slug, title { title } }
    contentfulNewsletterSignupPage { id, contentful_id, slug, title { title } }

    allContentfulSolutionCategory {
      nodes {
        id, contentful_id, slug
        title { title }
      }
    }

    allContentfulSolution {
      nodes {
        ...navContentfulSolution
      }
    }

    allContentfulResource {
      nodes {
        id, contentful_id, category, slug, title { title }
      }
    }

    allContentfulEvent {
      nodes {
        id, contentful_id, slug, title { title }
      }
    }

    allContentfulLocation {
      nodes {
        id, contentful_id, slug
        name { name }
      }
    }

    allContentfulGenericPage {
      nodes {
        id, contentful_id, slug, title { title }
      }
    }
  }

  fragment navContentfulSolution on ContentfulSolution {
    id, contentful_id, slug
    title { title }

    solutionCategory {
      id, contentful_id, slug
    }
  }
  `);

  const { siteTitle, siteLogo, primarySolutionCategories } = data.contentfulGlobalOptions ?? {};

  const navigationMap = buildNavigationMap(data);

  useEffect(() => {
    const cookieScript = document.createElement('script');
    cookieScript.src = 'https://cdn.cookie-script.com/s/11487e0f813729466b788d2dce1cc125.js';

    document.head.appendChild(cookieScript);

    return () => {
      document.head.removeChild(cookieScript);
    };
  });

  const canonicalLink = currentPath ? (
    <link rel="canonical" href={`https://www.ndt-global.com${currentPath}`} />
  ) : null;

  // Helmet:See https://www.gatsbyjs.com/plugins/gatsby-plugin-react-helmet
  return (
    <NavigationContext.Provider value={navigationMap}>
      <ClickDimensions>
        <Helmet titleTemplate={`${siteTitle} - %s`} defaultTitle={siteTitle}>
          <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
          <meta name="theme-color" content="#011a76" />
          <meta name="og:site_name" content={siteTitle} />
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#011a76" />
          <meta name="msapplication-TileColor" content="#b9b5b2" />
          {canonicalLink}
        </Helmet>
        <Navigation
          siteLogo={siteLogo}
          contentfulHomepage={data.contentfulHomepage}
          primarySolutionCategories={primarySolutionCategories}
          resourcesPage={data.contentfulResourcesPage}
          aboutPage={data.contentfulAboutPage}
          careersPage={data.contentfulCareersPage}
          contactUsPage={data.contentfulContactUsPage}
          searchPage={data.contentfulSearchPage}
        />
        {children}
        <Footer
          siteLogo={siteLogo}
          contentfulHomepage={data.contentfulHomepage}
          primarySolutionCategories={primarySolutionCategories}
          resourcesPage={data.contentfulResourcesPage}
          aboutPage={data.contentfulAboutPage}
          careersPage={data.contentfulCareersPage}
          contactUsPage={data.contentfulContactUsPage}
          newsletterSignupPage={data.contentfulNewsletterSignupPage}
        />
      </ClickDimensions>
    </NavigationContext.Provider>
  );
}
