import React from 'react';
import { graphql } from 'gatsby';
import RichText from '../rich-text';
import CtaButton from '../cta-button';
import './index.scss';

export default function CtaArrowsModule ({ ctaText, text, ctaTarget }) {
  return (
    <section className="cta-arrows-module">
      <div className="arrow-left">
        <RichText node={text} />
      </div>

      <div className="arrow-right">
        <CtaButton ctaText={ctaText} ctaTarget={ctaTarget} />
      </div>
    </section>

  );
}

export const query = graphql`
fragment ctaArrowsModuleFields on ContentfulCtaArrowsModule {
  ctaText

  text { raw }

  ctaTarget {
    __typename
    ... on ContentfulAboutPage { id }
    ... on ContentfulCareersPage { id }
    ... on ContentfulContactUsPage { id }
    ... on ContentfulEvent { id }
    ... on ContentfulGenericPage { id }
    ... on ContentfulNewsletterSignupPage { id }
    ... on ContentfulResource { id }
    ... on ContentfulResourcesPage { id }
    ... on ContentfulSolution { id }
    ... on ContentfulSolutionCategory { id }
  }
}
`;
