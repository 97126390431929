import React, { useState, useCallback, useMemo } from 'react';
import { Link } from 'gatsby';

import CtaButton from '../cta-button';
import ExtendedMenuLevel from './extended-menu-level';
import FeatureCard from './feature-card';
import chevronRightIcon from '../../images/icon-chevron-right-thick.svg';
import './extended-menu.scss';

export default function ExtendedMenu ({ solutionCategory, contactUsPage }) {
  const [ level2, setLevel2 ] = useState(false);
  const [ level3, setLevel3 ] = useState(false);

  const openLevel2 = useCallback((newLevel2, ev) => {
    ev.preventDefault();
    setLevel3(false);
    setLevel2(newLevel2);
  }, []);

  const openLevel3 = useCallback((newLevel3, ev) => {
    ev.preventDefault();
    setLevel3(newLevel3);
  }, []);

  const menuOverviewRendered = useMemo(() => {
    if (level3) return null;

    return (
      <div className="extended-menu-overview">
        <h4>{solutionCategory.title?.title}</h4>
        <p>{solutionCategory.description?.description}</p>
        <CtaButton
          ctaText="Learn More"
          ctaTarget={solutionCategory}
        />
      </div>
    );
  }, [ level3, solutionCategory ]);

  const featureContentRendered = useMemo(() => {
    if (level2 || !solutionCategory.featuredContent) return null;

    return (
      <div className="extended-menu-feature">
        <FeatureCard {...solutionCategory.featuredContent} />
      </div>
    );
  }, [ level2, solutionCategory ]);

  const contactFooter = (
    <p className="container extended-menu-contact-footer">
      <Link to={contactUsPage.path}>
        {solutionCategory.navigationContactLinkText}
        <img src={chevronRightIcon} alt="" className="chevron-right-icon" role="presentation" />
      </Link>
    </p>
  );

  return (
    <div className="extended-menu">
      <div className="container">
        {menuOverviewRendered}

        <ExtendedMenuLevel
          className="extended-menu-level1"
          parentItem={solutionCategory}
          handleOpenNextLevel={openLevel2}
        />

        <ExtendedMenuLevel
          className="extended-menu-level2"
          parentItem={level2}
          handleOpenNextLevel={openLevel3}
        />

        <ExtendedMenuLevel
          className="extended-menu-level3"
          parentItem={level3}
        />

        {featureContentRendered}
      </div>

      {contactFooter}
    </div>
  );
}
