import React, { useContext } from 'react';
import { graphql, Link } from 'gatsby';
import { BackgroundImage } from '../../components/images';
import RichText from '../rich-text';
import { NavigationContext } from '../navigation';
import CtaButton from '../cta-button';
import iconPlus from '../../images/icon-plus.svg';
import iconVideo from '../../images/icon-video.svg';
import iconDownload from '../../images/icon-download.svg';
import './index.scss';

const RESOURCE_TYPE_DEFAULT = 'Page';
const RESOURCE_TYPE_ICONS = {
  'Page': iconPlus,
  'Download': iconDownload,
  'Video': iconVideo
};

export default function FeaturedResourcesModule ({
  heading, ctaTarget, leadParagraph, text, ctaText, features
}) {
  const navigationMap = useContext(NavigationContext);

  const cards = features?.map((feature, i) => {
    return (
      <Link className="card-washed" to={navigationMap.get(feature.id)?.path || '#'} key={i}>
        <BackgroundImage image={feature.cardBackgroundImage} placeholder="tracedSVG" />
        <h6 className="card-sq-category">{feature.category}</h6>
        <h5 className="card-sq-heading">{feature.shortTitle}</h5>
        <img
          className="corner-icon"
          src={RESOURCE_TYPE_ICONS[feature.type ?? RESOURCE_TYPE_DEFAULT]}
          alt=""
          role="presentation"
        />
      </Link>
    );
  });

  return (
    <section className="featured-resources-module">
      <div className="container featured-resources-module__inner">
        <h2>{heading?.heading}</h2>
        <div className="featured-resources-module-grid">
          <aside>
            <div className="lead"><RichText node={leadParagraph} /></div>
            <RichText node={text} />
            <CtaButton ctaText={ctaText} ctaTarget={ctaTarget} />
          </aside>
          <div className="resource-cards">
            {cards}
          </div>
        </div>
      </div>
    </section>
  );
}

export const query = graphql`
fragment featuredResourcesModuleFields on ContentfulFeaturedResourcesModule {
  ctaText
  heading { heading }
  text { raw }
  leadParagraph { raw }

  ctaTarget {
    __typename
    ... on ContentfulAboutPage { id }
    ... on ContentfulCareersPage { id }
    ... on ContentfulContactUsPage { id }
    ... on ContentfulEvent { id }
    ... on ContentfulGenericPage { id }
    ... on ContentfulNewsletterSignupPage { id }
    ... on ContentfulResource { id }
    ... on ContentfulResourcesPage { id }
    ... on ContentfulSolution { id }
    ... on ContentfulSolutionCategory { id }
  }

  features {
    category, type, id, shortTitle

    cardBackgroundImage {
      gatsbyImageData(
        height: 260
        width: 650
        cropFocus: CENTER
        resizingBehavior: FILL
        quality: 60
        placeholder: TRACED_SVG
      )
    }
  }
}
`;
