import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import TextField from '@material-ui/core/TextField';
import searchIcon from '../../images/icon-search.svg';
import './search-form.scss';

export default React.forwardRef(function SearchForm ({
  searchPage,
  q,
  className = 'search-form',
  autoFocus = true
}, ref) {
  const [ value, setValue ] = useState(q);
  const [ isError, setIsError ] = useState(q && q.length < 3);

  const handleChange = (ev) => {
    setValue(ev.target.value);

    if (isError && ev.target.value.length >= 3) setIsError(false);
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();

    if (value.length < 3) {
      setIsError(true);
      return;
    } else if (isError) {
      setIsError(false);
    }

    const query = new URLSearchParams({ q: value });
    navigate(`${searchPage.path}?${query}`);
  };

  useEffect(() => {
    setValue(q);
  }, [ q ]);

  return (
    <form
      className={className}
      method="GET"
      ref={ref}
      action={searchPage.path}
      onSubmit={handleSubmit}
    >
      <TextField
        label="Search"
        color="primary"
        name="q"
        required={true}
        fullWidth={true}
        autoFocus={autoFocus}
        value={value || ''}
        onChange={handleChange}
        error={isError}
      />
      <button className="search-form-submit" type="submit">
        <img src={searchIcon} alt="Go" />
      </button>
    </form>
  );
});
