import React, { useState, useRef, useCallback, useMemo } from 'react';
import playIcon from './images/play_circle_filled.svg';
import pauseIcon from './images/pause_circle_filled.svg';
import './video.scss';

export default function Video ({ asset, poster }) {
  const videoRef = useRef(null);
  const pauseButtonRef = useRef(null);
  const [ showControl, setShowControl ] = useState('play');

  const handlePlayVideo = useCallback(() => {
    videoRef.current.play();
    setShowControl('pause');
    setTimeout(() => {
      pauseButtonRef.current?.classList?.add('fade-out');
    }, 500);
  }, [ videoRef, pauseButtonRef ]);

  const handlePauseVideo = useCallback(() => {
    videoRef.current.pause();
    setShowControl('play');
  }, [ videoRef ]);

  const controls = useMemo(() => {
    if (!showControl) return null;

    if (showControl === 'play') {
      pauseButtonRef.current = null;
      return (
        <div className="controls">
          <button className="play" onClick={handlePlayVideo}>
            <img src={playIcon} alt="Play" className="" />
          </button>
        </div>
      );
    }

    if (showControl === 'pause') {
      return (
        <div className="controls">
          <button className="pause" onClick={handlePauseVideo}>
            <img src={pauseIcon} alt="Pause" ref={pauseButtonRef} />
          </button>
        </div>
      );
    }

    throw new Error(`Unknown value for showControl: ${showControl}`);
  }, [ showControl, handlePlayVideo, handlePauseVideo, pauseButtonRef ]);

  return (
    <>
      <video
        poster={poster}
        src={asset.file?.url}
        type={asset.file?.contentType}
        ref={videoRef}
        onEnded={() => setShowControl('play')}
      />
      {controls}
    </>
  );
}
