import React from 'react';
import { graphql } from 'gatsby';
import RichText from '../rich-text';
import './index.scss';

export default function ArticleContentModule ({ heading, leadParagraph, text, hideHeading }) {
  const headingRendered = !hideHeading && heading?.heading ? (<h2>{heading?.heading}</h2>) : null;

  const leadParagraphRendered = (() => {
    if (!leadParagraph?.raw) return null;
    return (
      <div className="lead">
        <RichText node={leadParagraph} />
      </div>
    );
  })();

  return (
    <section className="article-content-module">
      {headingRendered}
      {leadParagraphRendered}
      <div className="text"><RichText node={text} /></div>
    </section>
  );
}

export const query = graphql`
fragment articleContentModuleFields on ContentfulArticleContentModule {
  hideHeading
  heading { heading }
  leadParagraph { raw }
  text { raw }
}
`;
