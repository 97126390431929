import React, { useContext } from 'react';
import { graphql, Link } from 'gatsby';
import { BackgroundImage } from '../../components/images';
import { NavigationContext } from '../navigation';
import cornerTriangleImg from '../../images/corner-triangle.svg';
import iconPlus from '../../images/icon-plus.svg';
import './index.scss';

export default function FeaturedSolutionsModule ({ heading, features }) {
  const navigationMap = useContext(NavigationContext);

  const cards = features?.map((feature, i) => {
    return (
      <Link className="card-washed" to={navigationMap.get(feature.id).path} key={i}>
        <BackgroundImage image={feature.cardBackgroundImage} placeholder="tracedSVG" />
        <h5 className="card-washed-title">{feature.shortTitle}</h5>
        <p className="card-washed-p">{feature.metaDescription?.metaDescription}</p>
        <img className="corner-triangle" src={cornerTriangleImg} alt="" role="presentation" />
        <img className="corner-icon" src={iconPlus} alt="" role="presentation" />
      </Link>
    );
  }) ?? null;

  return (
    <section className="featured-solutions-module">
      <div className="container featured-solutions-module__inner">
        <h4>{heading?.heading}</h4>
        <div
          className="solution-cards"
          style={{ gridTemplateColumns: `repeat(${features?.length || 0}, auto)` }}
        >
          {cards}
        </div>
      </div>
    </section>
  );
}

export const query = graphql`
fragment featuredSolutionsModuleFields on ContentfulFeaturedSolutionsModule {
  heading { heading }

  features {
    id
    shortTitle
    metaDescription { metaDescription }
    solutionCategory { id }

    cardBackgroundImage {
      gatsbyImageData(
        height: 500
        width: 400
        cropFocus: CENTER
        resizingBehavior: FILL
        quality: 60
        placeholder: TRACED_SVG
      )
    }
  }
}
`;
