import React, { useState, useContext } from 'react';
import Chevron from '../chevron';
import { Link } from 'gatsby';
import { NavigationContext } from './index';

export default function MobileNavSecondary ({ parentItem, items, active, setActive }) {
  const navigationMap = useContext(NavigationContext);
  const [ activeAccordion, setActiveAccordion ] = useState(null);

  const subMenuItems = items.map((solution, i) => {
    const { title, id, contentfulchildren } = solution;

    if (contentfulchildren?.length) {
      return (
        <li className="sub-menu-item" key={id}>
          <div
            className="nav-item-heading"
            onClick={() => setActiveAccordion(id)}
          >
            <span>{ title?.title }</span>
            <Chevron dir="right" />
          </div>
          <MobileNavSecondary
            parentItem={solution}
            items={contentfulchildren}
            active={activeAccordion === id}
            setActive={setActiveAccordion}
          />
        </li>
      );
    }

    return (
      <li className="sub-menu-item" key={id}>
        <Link to={navigationMap.get(id)?.path}>{title?.title}</Link>
      </li>
    );
  });

  return (
    <div className={`mobile-nav-secondary ${active ? 'active' : ''}`}>
      <div className="mobile-nav-secondary__inner">

        <div className="back-link" onClick={() => setActive(null)}>
          <Chevron dir="left" /> <span>Back</span>
        </div>

        <ul className="sub-menu">

          <li className="top-level-item sub-menu-item">
            <Link to={navigationMap.get(parentItem.id)?.path}>
              All {parentItem.title?.title}
            </Link>
          </li>

          {subMenuItems}

        </ul>
      </div>
    </div>
  );
}
